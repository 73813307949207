import Konva from 'konva';
import { getSnapPos } from '@/utils/Utils';

import { GRID, STAGE_SIZE } from '@/utils/Config';
import _ from 'lodash';

export default class Wall extends Konva.Rect {
  constructor(params) {
    const { config, stage, layer, onDone, isAction, onClick, onDb, simple } =
      params;

    const meta = {
      color: '#999999',
      type: 1,
      ...config.meta,
    };

    super({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      fill: meta.color,

      shadowColor: 'black',
      shadowBlur: 2,
      shadowOffset: {
        x: 1,
        y: 1,
      },
      shadowOpacity: 0.4,

      id: config.meta.id,
      type: 1,
      ...config,
      meta,
      draggable: simple !== true,
    });

    this.stage = stage;
    this.layer = layer;
    // 要加上檢察 給匯入用
    this.isAction = isAction;
    this.drawing = isAction;
    this.onClick = onClick;
    this.onDb = onDb;
    this.gridSize = GRID;
    this.tr = {};
    this.active = false;

    this.layer.add(this);
    if (simple !== true) {
      this.bind();
      this.createTransform();
      this.onDone = onDone;
    }
  }

  updateColor() {
    this.fill(this.attrs.meta.color);
  }
  toggleActive(active) {
    this.active = active == false ? active : !this.active;
    // this.fill(this.active ? this.attrs.meta.color : '#cccccc');
    this.tr.resizeEnabled(this.active);
    this.tr.borderEnabled(this.active);
  }
  kill() {
    this.tr.destroy();
    // this.text.destroy()
    this.destroy();
  }
  // getZoomPos(pos) {
  //   return {
  //     x: pos.x / this.stage.scaleX(),
  //     y: pos.y / this.stage.scaleY(),
  //   };
  // }
  bind() {
    this.on('dragend', (e) => {
      // snap
      this.position({
        x: Math.round(this.x() / this.gridSize) * this.gridSize,
        y: Math.round(this.y() / this.gridSize) * this.gridSize,
      });

      this.stage.batchDraw();
    });

    this.stage.on('mousemove', () => {
      if (!this.drawing) return;
      const _pos = this.stage.getRelativePointerPosition();

      const points = this.position();
      this.size({
        width: _pos.x - points.x,
        height: _pos.y - points.y,
      });

      this.layer.batchDraw();
    });

    this.stage.on('mouseup mouseleave', (e) => {
      if (!this.drawing) return;
      this.drawing = false;
      const ori = this.position();
      const _pos = getSnapPos(this.stage.getRelativePointerPosition());

      this.size({
        width: Math.max(_pos.x - ori.x, this.gridSize),
        height: Math.max(_pos.y - ori.y, this.gridSize),
      });
      this.onDone();
      this.layer.draw();
    });
    this.on('mousedown tap', (e) => {
      this.onClick(this.attrs.id, this.attrs.meta.type);
    });
    this.on('dblclick dbltap', (e) => {
      this.onDb(this.attrs.id);
    });
  }
  createTransform() {
    this.tr = new Konva.Transformer({
      nodes: [this],
      rotateEnabled: false,
      resizeEnabled: true,
      borderStroke: '#000000',
      anchorStroke: '#000000',
      enabledAnchors: ['bottom-right'],
      // borderEnabled: false,
      keepRatio: false,
    });
    this.on('transformend', (e) => {
      this.width(
        Math.max(
          this.gridSize,
          Math.round((this.scaleX() * this.width()) / this.gridSize) *
          this.gridSize
        )
      );

      this.scaleX(1);
      this.height(
        Math.max(
          this.gridSize,
          Math.round((this.scaleY() * this.height()) / this.gridSize) *
          this.gridSize
        )
      );
      this.scaleY(1);
    });

    this.layer.add(this.tr);
  }
}
