import Konva from 'konva';
import { getGridIdx, fixTransform } from '@/utils/Utils';

import { GRID, STAGE_SIZE, ITEM_TYPE_COLORS } from '@/utils/Config';
import { getCenter } from '@/utils/Utils';
import _ from 'lodash';
export default class Item extends Konva.Rect {
  constructor(params) {
    const { config, stage, layer, isAction, onClick, onDb, simple } = params;

    const meta = {
      offsetY: 0,
      offsetX: 0,
      ...config.meta,
    };

    super({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      rotation: 0,
      stroke: ITEM_TYPE_COLORS[meta.type],
      strokeWidth: 3,
      hitStrokeWidth: 4,

      fill: '#ebebeb',
      shadowColor: 'black',
      // shadowBlur: 2,
      // shadowOffset: {
      //   x: 1,
      //   y: 1,
      // },
      // shadowOpacity: 0.4,
      draggable: simple !== true,
      ...config,
      meta,
    });

    this.stage = stage;
    this.layer = layer;
    this.isAction = isAction;
    this.onClick = onClick;
    this.onDb = onDb;

    this.gridSize = GRID;
    this.tr = null;
    this.active = false;

    this.spotBox = null;

    this.layer.add(this);

    if (isAction) {
      this.width(Math.ceil(config.meta.l / this.gridSize) * this.gridSize);
      this.height(Math.ceil(config.meta.w / this.gridSize) * this.gridSize);
      this.update();
    }

    this.updateSpot();
    if (simple !== true) {
      this.bind();
      this.createTransform();
    }
    this.createText();
  }

  createText() {
    this.text = new Konva.Text({
      text: this.attrs.meta.idx + 1,
      fontSize: 16,
      fill: '#000',

      hitFunc: (e) => {
        return false;
      },
    });

    this.updateTextPos();

    this.layer.add(this.text);
  }
  updateIdx(idx) {
    this.attrs.meta.idx = idx;
    this.text.text(this.attrs.meta.idx + 1);

    this.redraw();
  }

  kill() {
    this.tr.destroy();
    this.text.destroy();
    this.destroy();
  }

  redraw() {
    this.layer.batchDraw();
  }
  toggleActive(active) {
    this.active = active == false ? active : !this.active;
    this.fill(this.active ? '#999999' : '#cccccc');

    this.tr.rotateEnabled(this.active);
    this.tr.borderEnabled(this.active);
  }
  updateFrame(toggle) {
    this.attrs.meta.frame = toggle;
  }

  updateWidth(w) {
    this.width(Math.ceil(w / this.gridSize) * this.gridSize);
  }
  updateTextPos() {
    const pos = getCenter(
      this.x(),
      this.y(),
      this.width(),
      this.height(),
      this.rotation()
    );

    const fontSize = this.text.attrs.fontSize;
    if (pos) {
      this.text.position({
        x: pos.x - fontSize / 3,
        y: pos.y - fontSize / 3,
      });
    }

    this.layer.batchDraw();
  }

  bind() {
    this.on('dragmove', function () {
      this.updateTextPos();
      this.stage.batchDraw();
    });

    this.on('dragend', (e) => {
      this.position({
        x: Math.round(this.x() / this.gridSize) * this.gridSize,
        y: Math.round(this.y() / this.gridSize) * this.gridSize,
      });
      this.updateTextPos();
      this.update();
      // need id name or idx. 現在只會新增觀察點

      this.layer.batchDraw();
    });
    this.on('mousedown tap', (e) => {
      // this.updateRotateAnchorOffset();
      this.onClick(this.attrs.id, this.attrs.meta.type, true);
    });
    this.on('dblclick dbltap', (e) => {
      this.onDb(this.attrs.id);
    });
  }

  createTransform() {
    this.tr = new Konva.Transformer({
      nodes: [this],
      rotationSnaps: [0, 90, 180, 270],
      rotationSnapTolerance: 45,
      rotateEnabled: true,
      resizeEnabled: false,
      anchorSize: 15,
      borderStroke: '#666',
      anchorStroke: '#000000',
      borderEnabled: true,
      offsetY: 0,
      anchorStrokeWidth: 1,
      rotateAnchorOffset: this.spotOffset * this.stage.scaleX(),
      anchorCornerRadius: 50,
      enabledAnchors: ['bottom-right'],
      keepRatio: false,
      useSingleNodeRotation: false,

      // 滾輪要用 因為transform 部會zoom
      _rotateAnchorOffset: this.spotOffset,
    });

    this.on('transformend', (e) => {
      fixTransform(this);
      this.position({
        x: Math.round(this.x()),
        y: Math.round(this.y()),
      });

      this.update();
    });
    this.layer.add(this.tr);
  }
  updateRotateAnchorOffset() {
    this.tr.rotateAnchorOffset(this.spotOffset * this.stage.scaleX());
    this.tr.attrs._rotateAnchorOffset = this.spotOffset;
    this.tr.forceUpdate();
  }
  updateSpot() {
    let pos;
    this.spotOffset =
      Math.min(GRID * 8, Math.max(this.width(), this.height())) + GRID * 2;

    if (this.tr) {
      this.updateRotateAnchorOffset();
    }

    switch (Math.round(this.rotation())) {
      case 0:
        pos = {
          x: this.x() + this.width() / 2,
          y: this.y() - this.spotOffset,
        };
        break;
      case 90:
        pos = {
          x: this.x() + this.spotOffset,
          y: this.y() + this.width() / 2,
        };
        break;
      case -180:
      case 180:
        pos = {
          x: this.x() - this.width() / 2,
          y: this.y() + this.spotOffset,
        };
        break;
      case -90:
      case 270:
        pos = {
          x: this.x() - this.spotOffset,
          y: this.y() - this.width() / 2,
        };
        break;
    }

    const spotIdx = getGridIdx(pos);

    this.attrs.meta.spot = spotIdx;
    this.attrs.meta.spotX = pos.x;
    this.attrs.meta.spotY = pos.y;
  }
}
